<template>
    <button class="custom_search_button" @click="clickFunction" :disabled="disableState">
        <img src="@/images/sprites/svg/search.svg" alt="Search Panel" draggable="false"/>
    </button>
</template>
<script>

export default {
    name: 'CommonSearchButton',
    props: {
        clickFunction: {
            type: Function,
            required: true,
        },
        disableState: {
            type: Boolean,
            required: false,
        },
    },
}
</script>
<style>       
.custom_search_button {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: #0085FF;
    color: #fff;
    flex-shrink: 0;
    cursor: pointer;
    &:hover {
        background: #006acc;
    }
    &:disabled {
        background-color: #EBEFF5;
        border: 1px solid #EBEFF5;
        cursor: default;
        img {
            filter: brightness(0) saturate(100%) invert(52%) sepia(1%) saturate(861%) hue-rotate(350deg) brightness(86%) contrast(82%);
        }
    }
}
</style>