<template>
    <div class="selected_additionally_title">
        <div class="selected_additionally_icon" v-if="iconPath">
            <img :src="iconPath" alt="">
        </div>
        <div class="selected_additionally_provider">
            {{providerName}} &#149; {{gameName}}
        </div>
    </div>
</template>

<script>

export default {
    name: "SelectedItemTagTitle",
    data() {
        return {
        }
    },
    props: {
        iconPath: {
            type: String,
            required: true,
        },
        providerName: {
            type: String,
            required: true,
        },
        gameName: {
            type: String,
            required: true,
        },
    },
}
</script>

<style lang="scss">
    .selected_additionally_title {
        display: flex;
        align-items: center;
        align-content: center;
        font-weight: 600;
        gap: 4px;
        .selected_additionally_icon {
            img {
                display: block;
            }
        }
        .selected_additionally_provider {
            text-align: left;
            align-self: center;
            min-height: 34px;
            display: inline-flex;
            align-items: center;
            padding-right: 4px;
            padding-bottom: 2px;
        }
    }
</style>
