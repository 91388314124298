export default {
    PATH_NAME: {
        AUTHORIZATION: 'authorization',
        SIGN_UP: 'signup',
        MAIN: 'main',
        SEARCH: 'search',
        COLLECTION: 'collection',
        HISTORY: 'history',
        SETTINGS: 'settings',
        PROFILE: 'profile',
        NOT_FOUND: '404',
    },
    SIZE_PAGE: 50,
    DEFAULT_CURRENT_PAGE_NUM: 1,
    DEFAULT_MIN_GAME_NUMBER: 5,
    DEFAULT_MIN_FILTER_NUMBER: 5,
    MIN_HEIGHT_SELECTED_BLOCK: 446,
    UPSIZING_VALUE: 90,
    MIN_WIDTH_MAIN_DISPLAY: 360,
    MIN_HEIGHT_COLLECT_DISPLAY: 200,
    MIN_HEIGHT_WITH_OPEN_WINDOW: 536,
    MIN_HEIGHT_SELECT_DISPLAY: 446,
    MAIN_ASSET_DEFAULT: 'images',
    ASSETS: 'assets',
    PROVIDERS: 'providers',
    GAMES: 'games',
    FILTERS: 'filters',
    TAGS: 'tags',
    HQ_ONLY: 'hq_only',
    TAG_ELEMENT: 'tagElement',
    SEARCH_REQUEST : 'search_request',
    FILTER_TYPE_ASSET : 'asset_type',
    DEFAULT_ACTIVE_MAIN_ASSET: 'Images',
    DEBOUNCE_DELAY: 500,
    MIN_HEIGHT_DROPAREA: 300,
    FILTER_PROPORTION: 'proportions',
    PROPORTION_HORIZONTAL: 'horizontal',
    PROPORTION_SQUARE: 'square',
    PROPORTION_VERTICAL: 'vertical',
    MIN_MATCH_SYMBOL: 3,
    FILTER_TYPE: {
        POPUP: 'popup',
        CHECKBOX: 'checkbox',
    },
    COUNT_ROWS_IN_COLUMN: 15,
    POPUP_DISTANCE: {
        UP: 30,
        DOWN: 48,
    },
    STATUS_CODE: {
        OK: 200,
        CREATED_SUCCESS: 201,
        BAD_REQUEST: 400,
        BAD_LOG_IN: 422,
        UNAUTHORIZED: 401,
        FORBIDDEN: 403,
    },
    VALID_STATUS: {
        DEFAULT: 0,
        SUCCESS: 1,
        FAIL: 2,
    },
    EMAIL_REGEXP: /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu,
    COMPANY_NAME_REGEXP: /^[a-zA-Zа-яА-Я0-9]+(?: [a-zA-Zа-яА-Я0-9]+)*$/iu,
    MIN_VALID_SYMBOL_PASSWORD: 8,
    LOG_IN_NAME: 'log in',
    IS_VIEW_POPUP_CLEAR_FILES_NUMBER: 10,
    LOCAL_STORAGE_NAME_USER: '_DATA_USER',
    LOCAL_STORAGE_NAME_PRECOLLECT: 'COLLECTION_LIST_PREVIEW',
}