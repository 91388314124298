<template>
    <div class="popup_panel_body">
        <div class="popup_panel_add_area">
            <div class="panel_create_wrapper add_area_item">
                <div class="panel_create_title add_area_item_title">
                    Create collection
                </div>
                <div class="popup_panel_create_body add_area_item_body">
                    <CustomInputComponent
                        ref="input_new_collection_name"
                        :typeInput="'text'"
                        :valueTextInput="''"
                        :placeholderInput="'Name collection'"
                        :changeFunction="changeCollectNameInput"/>
                    <div class="popup_panel_btn-create">
                        <GeneralAddButton
                          :clickFunction="createNewCollection"
                          :disableState="!userCollectionName.length"
                        />
                    </div>
                </div>
            </div>
            <div class="panel_search_wrapper add_area_item" v-if="getUserCollectionsData.length">
                <div class="panel_search_title add_area_item_title">
                    Choose
                </div>
                <div class="panel_search_body add_area_item_body">
                    <CustomInputComponent
                        :typeInput="'text'"
                        :placeholderInput="'Collection Search'"
                        :changeFunction="searchCollectInput"/>
                    <div class="popup_panel_btn-filter">
                        <CommonSearchButton
                            :clickFunction="startCollectionSearch"
                            :disableState="!searchCollectValue.length"/>
                    </div>
                </div>
            </div>
        </div>
        <ul class="collection_list" v-if="userCollectionsData.length">
            <CollectionItemComponent
              v-for="userCollection in userCollectionList"
              :collectionItem="userCollection"/>
        </ul>
    </div>
</template>

<script>
import CustomInputComponent from '@/components/Others/CustomInputComponent/CustomInputComponent.vue';
import CommonSearchButton from '@/components/Others/CommonSearchButton/CommonSearchButton.vue';
import GeneralAddButton from '@/components/Others/GeneralAddButton/GeneralAddButton.vue';
import CollectionItemComponent from '../CollectionItemComponent/CollectionItemComponent.vue';
import constants from '@/constants/constants';
import searchHandler from '@/utils/searchHandler/searchHandler';

export default {
    name: "PopupPanelAddComponent",
    components: {
        CollectionItemComponent,
        CustomInputComponent,
        CommonSearchButton,
        GeneralAddButton,
    },
    data () {
        return {
            userCollectionsData: [],
            userCollectionList: [],
            userCollectionName: '',
            searchCollectValue: '',
        }
    },
    methods: {
        changeCollectNameInput(value) {
            this.userCollectionName = value;
        },
        createNewCollection() {
            this.$store.dispatch('CREATE_NEW_COLLECTION', this.userCollectionName).then(res => {
                const {STATUS_CODE} = constants;
                if(res.status === STATUS_CODE.OK) {
                    this.$refs.input_new_collection_name.valueInput = '';
                }
            })
        },
        searchCollectInput(value) {
            this.searchCollectValue = value;
            if(!value.length) {
                this.userCollectionList = this.userCollectionsData;
            }
        },
        startCollectionSearch() {
            this.userCollectionList = !!this.searchCollectValue ? searchHandler(this.searchCollectValue, this.userCollectionsData) : this.userCollectionsData;
        },
    },
    computed: {
        getUserCollectionsData() {
            this.userCollectionsData = this.$store.getters.USER_COLLECTIONS_DATA;
            this.userCollectionList = [...this.userCollectionsData];
            return this.userCollectionsData;
        },
    },
}
</script>

<style lang="scss">
.popup_panel_body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    .popup_panel_add_area {
        position: relative;
        width: 100%;
        max-height: 173px;
        padding-bottom: 20px;
        padding-right: 12px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 20px;
        &::after {
            content: '';
            position: absolute;
            bottom: -1px;
            width: 97.3%;
            height: 1px;
            background-color: #EBEFF5;
        }
        .add_area_item {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            gap: 6px;
            .add_area_item_title {
                font-size: 16px;
                font-weight: 500;
            }
        }
        .add_area_item_body {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 8px;
        }
    }
    .collection_list {
        width: 100%;
        max-height: 158px;
        padding-right: 8px;
        list-style: none;
        overflow-y: auto;
        scrollbar-gutter: stable;
    }
}
</style>