<template>
    <div class="collect__images__nav">
        <button class="collect__images__btn collect__images__btn-clear" @click="clickToClear">
            <img src="@/images/sprites/svg/clear.svg" alt="">
        </button>
        <div class="collect__images__btns">
            <button class="collect__images__btn collect__images__btn-add" @click="openAddToCollectPopup">
                <img src="@/images/icons/to-collect-icon.svg" alt="">
            </button>
            <button class="collect__images__btn collect__images__btn-load" @click="downloadCollectImages">
                <div class="btn_load_title" v-if="!isLoading">
                    <img src="@/images/sprites/svg/download.svg" alt=""> zip
                </div>
                <div class="btn_load-start" v-if="isLoading" @click="cancelDownloadFiles">
                    <LoaderComponent/>
                </div>
            </button>
        </div>
        <ClearAllPrecollectPopup v-if="isOpenPopUp" :clearAllCollect="clearAllCollect" :closePoUp="closePoUp"/>
    </div>
</template>

<script>
import ClearAllPrecollectPopup from '@/components/PopUpComponents/ClearAllPrecollectPopup/ClearAllPrecollectPopup.vue';
import LoaderComponent from '@/components/Others/LoaderComponent.vue';
import constants from '@/constants/constants';

export default {
    name: "CollectNavigationButtons",
    data() {
        return {
            isOpenPopUp: false,
            isLoading: false,
        }
    },
    components: {
        ClearAllPrecollectPopup,
        LoaderComponent,
    },
    methods: {
        clickToClear() {
            const arrCollect = this.$store.getters.DATA_PRECOLLECT;
            if(!arrCollect.length) return;
            if(arrCollect.length > constants.IS_VIEW_POPUP_CLEAR_FILES_NUMBER) {
                this.isOpenPopUp = true;
            } else {
                this.clearAllCollect();
            }

        },
        clearAllCollect() {
            this.isOpenPopUp = false;
            this.$store.commit('CLEAR_ALL_DATA_PRECOLLECT', [])
        },
        closePoUp() {
            this.isOpenPopUp = false;
        },
        downloadCollectImages() {
            if(this.isLoading) return;

            const arrCollect = this.$store.getters.DATA_PRECOLLECT;
            this.isLoading = true;

            if(arrCollect.length > 1) {
                this.downloadFiles();
            } else if (arrCollect.length) {
                const { id } = arrCollect[0];
                this.downloadFile(id);
            };
        },
        downloadFile(id) {
            this.$store.dispatch('DOWNLOAD_FILE', id).then((res) =>{
                if(res.status === constants.STATUS_CODE.OK) {
                    const link = document.createElement('a');
                    link.href = res.data;
                    link.click();
                }
            }).catch((err) => {
                console.error(`${err.name}: An error occurred while uploading the file`);
            }).finally(() => {
                this.isLoading = false;
            })
        },
        downloadFiles() {
            this.$store.dispatch('DOWNLOAD_FILES').then((res) =>{
                if(res?.status === constants.STATUS_CODE.OK) {
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const linkElement = document.createElement('a');
                    linkElement.href = url;
                    linkElement.setAttribute('download', 'archiveFiles_GameArtKit.zip');
                    linkElement.click();
                }
            }).catch((err) => {
                console.error(`${err.name}:${err.message} An error occurred while uploading the file`);
            }).finally(() => {
                this.isLoading = false;
            })
        },
        cancelDownloadFiles(event) {
            event.stopPropagation();
            this.$store.dispatch('CANCEL_DOWNLOAD_FILES');
        },
        openAddToCollectPopup() {
            this.$store.commit('CHANGE_VIEW_COLLECTION_POPUP',  {isOpen: true, addOnlyOneElement: false})
        },
    }
}
</script>

<style lang="scss">
    .collect__images__nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        .collect__images__btn {
            height: 50px;
            min-width: 50px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border: none;
            border-radius: 10px;
            background-color: #fff;
            font-weight: 600;
            font-size: 15px;
            padding: 0 20px;
            cursor: pointer;
            text-decoration: none;
        }
        .collect__images__btn-clear:hover {
            img {
                filter: brightness(0) saturate(100%) invert(37%) sepia(38%) saturate(6479%) hue-rotate(343deg) brightness(98%) contrast(106%);
            }
        }
        .collect__images__btns {
            display: flex;
            gap: 10px;
            .collect__images__btn:hover {
                color: #7cb2ff;
                img {
                    filter: brightness(0) saturate(100%) invert(86%) sepia(35%) saturate(6545%) hue-rotate(187deg) brightness(100%) contrast(104%);
                }
            }
            .collect__images__btn-load {
                position: relative;
                width: 92px;
                height: 50px;
                display: inline-flex;
                .btn_load_title {
                    display: inline-flex;
                    gap: 5px;
                    align-content: center;
                    justify-content: center;
                    align-items: center;
                    img {
                        display: block;
                    }
                }
                .btn_load-start {
                    position: absolute;
                    border-radius: 10px;
                    width: 100%;
                    height: 100%;
                    .loader_container {
                        .lds-spinner {
                            max-width: 10px;
                            height: 10px;
                        }
                        .lds-spinner div {
                            transform-origin: 4px 4px;
                        }
                        .lds-spinner div:after {
                            top: 1.2px;
                            left: 9.8px;
                            width: 12.4px;
                            height: 3px;
                            border-radius: 10%;
                        }
                    }
                    &:hover {
                        filter: brightness(0) saturate(100%) invert(37%) sepia(38%) saturate(6479%) hue-rotate(343deg) brightness(98%) contrast(106%);
                    }
                }
            }
        }
    }
</style>