import constants from "@/constants/constants";
import apiRequest from "@/utils/apiRequest/apiRequest";

const state = {
    isAddToCollectionPopupOpen: false,
    userCollectionsData: [],
    collectionListForUpdate: [],
    addOnlyOneElement: true,
};

const getters = {
  IS_ADD_TO_COLLECTION_POPUP_OPEN: state => {
    return state.isAddToCollectionPopupOpen;
  },
  USER_COLLECTIONS_DATA: state => {
    return state.userCollectionsData;
  },
  COLLECTIONS_LIST_FOR_UPDATE: state => {
    return state.collectionListForUpdate;
  },
  ADD_ONLY_ONE_ELEMENT: state => {
    return state.addOnlyOneElement;
  },
};

const mutations = {
  CHANGE_VIEW_COLLECTION_POPUP: (state, payload) => {
    state.isAddToCollectionPopupOpen = payload.isOpen;
    state.addOnlyOneElement = payload.addOnlyOneElement;
  },
  SET_USER_COLLECTIONS: (state, payload) => {
    state.userCollectionsData = payload;
  },
  ADD_COLLECTION_TO_LIST_FOR_UPDATE: (state, payload) => {
    state.collectionListForUpdate.push(payload);
    console.log(state.collectionListForUpdate)
  },
  REMOVE_COLLECTION_TO_LIST_FOR_UPDATE: (state, payload) => {
    state.collectionListForUpdate = state.collectionListForUpdate.filter(({id}) => id !== payload);
    console.log(state.collectionListForUpdate)
  },
  CLEAR_COLLECTION_TO_LIST_FOR_UPDATE: (state, payload) => {
    state.collectionListForUpdate = [];
  },
  UPDATE_USER_COLLECTIONS: (state, payload) => {
    state.userCollectionsData.push(payload);
  },
};

const actions = {
  GET_USER_COLLECTIONS: async ({getters, commit}, payload) => {
    try {
      const response = await apiRequest({
        url: `/user/user_collection`,
        method: 'GET',
        headers: {
          "Cache-Control": "no-cache",
          "Authorization": `Bearer ${getters.ACCESS_TOKEN}`,
        },
      });
      const res = response.data;
      // console.log(res)
      commit('SET_USER_COLLECTIONS', res);
    } catch (err) {
      console.error(`${err.name}: ${err.message}`);
    }
  },
  CREATE_NEW_COLLECTION: async ({getters, commit, dispatch}, collectionName) => {
    console.log(collectionName);
    try {
      const response = await apiRequest({
        url: '/user/user_collection',
        method: 'POST',
        headers: {
          "Cache-Control": "no-cache",
          "Authorization": `Bearer ${getters.ACCESS_TOKEN}`,
        },
        data: {
          "name": collectionName,
          "files_ids": [],
        }
      });

      const res = response.data;
      console.log(res)
      commit('UPDATE_USER_COLLECTIONS', res);
      return response;
    } catch (err) {
      return err.response;
      console.error(`${err.name}: ${err.message}`);
    }
  },
  DELETE_COLLECTION: async ({getters, commit, dispatch}, collectionID) => {
    console.log(collectionID);
    try {
      const response = await apiRequest({
        url: `/user/user_collection?collection_id=${collectionID}`,
        method: 'DELETE',
        headers: {
          "Cache-Control": "no-cache",
          "Authorization": `Bearer ${getters.ACCESS_TOKEN}`,
        },
      });

      const res = response.data;
      console.log(res)
      dispatch('GET_USER_COLLECTIONS', '');
      return response;
    } catch (err) {
      return err.response;
      console.error(`${err.name}: ${err.message}`);
    }
  },
  UPDATE_COLLECTION: async ({getters, commit, dispatch}, collection, onlyName) => {
    let updatedFilesID = collection.files.map(({id}) => id);
    if(!onlyName) {
      let newFiles = getters.ADD_ONLY_ONE_ELEMENT ?  [getters.ASIDE_IMAGE_DATA ] : getters.DATA_PRECOLLECT;
      newFiles = newFiles.map(({id}) => id);
      updatedFilesID.push(...newFiles);
    }

    try {
      const response = await apiRequest({
        url: '/user/user_collection',
        method: 'PATCH',
        headers: {
          "Cache-Control": "no-cache",
          "Authorization": `Bearer ${getters.ACCESS_TOKEN}`,
        },
        data: {
          "id": collection.id,
          "name": collection.name,
          "files_ids": updatedFilesID,
        }
      });

      const res = response.data;
      // console.log(res)
      dispatch('GET_USER_COLLECTIONS', '');
      return response;
    } catch (err) {
      console.error(`${err.name}: ${err.message}`);
      return err.response;
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
}