<template>
    <CommonPopupContainer
      :popUpTitle="'Save to...'"
      :functionClosePopUp="functionClosePopUp">
        <div class="popup_panel_wrapper">
            <PopupPanelAddComponent/>
            <div class="popup_panel_btn-save">
                <CustomButton
                  :nameButton="'Save'"
                  :clickFunction="addElementsToCollections"
                  :styleType="0"
                  :iconImage="iconCollect"
                  :disableState="isCollectionListForUpdate"/>
            </div>
        </div>
    </CommonPopupContainer>
</template>

<script>
import CommonPopupContainer from '@/components/Others/CommonPopupContainer/CommonPopupContainer.vue';
import PopupPanelAddComponent from './PopupPanelAddComponent/PopupPanelAddComponent.vue';
import CustomButton from '@/components/Others/CustomButton/CustomButton.vue';
import iconCollect from '@/images/icons/to-collect-icon.svg';
import constants from '@/constants/constants';

export default {
    name: "AddToCollectionPopupComponent",
    components: {
        CommonPopupContainer,
        PopupPanelAddComponent,
        CustomButton,
    },
    data () {
        return {
            iconCollect,
            isBtnSaveDisable: true,
        }
    },
    props: {
        functionClosePopUp: {
            type: Function,
            required: true,
        },
        // functionAction: {
        //     type: Function,
        //     required: true,
        // },
        // popUpButtonAccept: {
        //     type: String,
        //     required: true,
        // },
        // isIconView: {
        //     type: Boolean,
        //     required: true,
        // }
    },
    methods: {
        addElementsToCollections() {
            const collectionsForUpdate = this.$store.getters.COLLECTIONS_LIST_FOR_UPDATE;
            Promise.all(collectionsForUpdate.map((collection) => {
                return this.$store.dispatch('UPDATE_COLLECTION', collection, false);
            })).then(res => {
                const {STATUS_CODE} = constants;
                if(res[0].status === STATUS_CODE.OK) {
                    this.functionClosePopUp();
                }
            })
        },
    },
    computed: {
        isCollectionListForUpdate() {
            return !this.$store.getters.COLLECTIONS_LIST_FOR_UPDATE.length;
        },
    },
    unmounted() {
        this.$store.commit('CLEAR_COLLECTION_TO_LIST_FOR_UPDATE')
    },
}
</script>

<style lang="scss">
.popup_panel_wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 22px;
    .popup_panel_btn-save {
        width: 100%;
        height: 40px;
        padding-right: 12px;
    }
}
</style>