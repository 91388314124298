<template>
    <div class="fullsize_popup_wrapper" @click="functionClose">
        <div class="fullsize_popup_body">
            <img :src="viewElement" class="fullsize_popup_element"/>
        </div>
    </div>
</template>
<script>
export default {
    name: 'FullSizePopupComponent',
    props: {
        viewElement: {
            type: String,
            required: true,
        },
        functionClose: {
            type: Function,
            required: true,
        }
    },
}
</script>
<style scoped lang="scss">
    .fullsize_popup_wrapper {
        position: fixed;
        z-index: 100;
        background-color: rgba(0, 0, 0, 0.8);
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        .fullsize_popup_body {
            width: 90%;
            height: 90%;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            img{
                max-height: 100%;
            }
        }
    }

</style>