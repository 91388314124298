<template>
    <div class="sort_component_wrapper">
        <div class="sort_component_item" @click="openSortWindow">
            <div class="sort_component_title">
                {{ getActiveSort.name }}
            </div>
            <div class="filters_panel_type-arrow">
                <img src="@/images/sprites/svg/arrow-down.svg" :class="!isOpenSortWindow ? 'arrow_image-down' : 'arrow_image-down arrow_image-up'">
            </div>
        </div>
        <div class="sort_component_window" v-if="isOpenSortWindow">
            <ul class="sort_component_window_list">
                <li class="sort_component_window_item" v-for="sortType in sortTypeData">
                    <CustomRadioComponent 
                        :radioInfo="sortType"
                        :radioChangeFunction="radioChangeFunction"/>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import CustomRadioComponent from '@/components/Others/CustomRadioComponent/CustomRadioComponent.vue';

export default {
    name: 'CommonSortingComponent',
    data () {
        return {
            isOpenSortWindow: false,
            isOpenSelectWindow: false,
            activeSort: null,
        }
    },
    props: {
        sortTypeData: {
            type: Array,
            required: true,
        }
    },
    components: {
        CustomRadioComponent,
    },
    methods: {
        openSortWindow() {
            this.isOpenSelectWindow = false;
            this.isOpenSortWindow = !this.isOpenSortWindow;
        },
        eventClick(e) {
            const target = !e.target.closest('.sort_component_wrapper')
            if(target) {
                this.isOpenSortWindow = false;
            }
        },
        radioChangeFunction(e) {
            this.activeSort = e.target.value;
            this.sortTypeData.forEach(sortFilter => {
                sortFilter.checked = sortFilter.name === this.activeSort;
            })
        }
    },
    watch: {
        isOpenSortWindow(value) {
            if(value) {
                window.addEventListener('click', this.eventClick)
            } else {
                window.removeEventListener('click', this.eventClick)
            }
        }
    },
    computed: {
        getActiveSort() {
            this.activeSort = this.sortTypeData.filter(({ checked }) => checked)[0];
            return this.activeSort;
        }
    },
}
</script>

<style scoped lang="scss">
        .sort_component_wrapper {
            position: relative;
            .sort_component_item {
                display: flex;
                justify-content: center;
                align-content: center;
                align-items: center;
                font-size: 16px;
                font-weight: 500;
                gap: 4px;
                cursor: pointer;
                &:hover {
                    .sort_component_title,
                    .filters_panel_type-arrow {
                        filter: brightness(0) saturate(100%) invert(34%) sepia(97%) saturate(2994%) hue-rotate(196deg) brightness(105%) contrast(103%);
                    }
                }
                .sort_component_title {
                    min-width: 60px;
                    text-align: right;
                    white-space: nowrap;
                }
                .filters_panel_type-arrow {
                    display: flex;
                    justify-content: center;
                    align-content: center;
                    align-items: center;
                    opacity: 0.4;
                    .arrow_image-up {
                      transform: scaleY(-1);
                    }
                }
            }
            .sort_component_window {
                position: absolute;
                top: 30px;
                right: 0;
                z-index: 100;
                min-height: 112px;
                padding: 10px 20px;
                border-radius: 8px;
                background-color: #FFFFFF;
                display: flex;
                justify-content: center;
                align-items: center;
                border: solid 1px #EBEFF5;
                box-shadow: 2px 2px 16px rgba(0, 0, 0, 0.1);
                .sort_component_window_list {
                    list-style: none;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 8px;
                    padding: 0;
                    margin: 0;
                    cursor: default;
                    .sort_component_window_item {
                        white-space: nowrap;
                    }
                }
            }
        }
</style>
