import apiRequest from '@/utils/apiRequest/apiRequest';

const state = {
    listDownloadFiles: [],
    cancelTokenSource: null,
};

const getters = {
  LIST_DOWNLOAD_FILES: state => {
    return state.listDownloadFiles;
  },
  CANCEL_TOKEN_SOURCE: state => {
    return state.cancelTokenSource;
  },
};

const mutations = {
  ADD_LIST_DOWNLOAD_FILES: (state, payload) => {
    const isFile = state.listDownloadFiles.find(fileId => fileId === payload);
    if(!isFile) {
        state.listDownloadFiles = payload;
    }
  },
  CHANGE_CANCEL_TOKEN_SOURCE: (state, payload) => {
    state.cancelTokenSource = payload;
  },
  REMOVE_FILE: (state, payload) => {
    state.listDownloadFiles = state.listDownloadFiles.filter(fileId => fileId !== payload);
  },
  REMOVE_LIST_DOWNLOAD_FILES: (state, payload) => {
    state.listDownloadFiles = [];
  },
};

const actions = {
    DOWNLOAD_FILE: async function({ getters }, payload) {
        try {
            const response = await apiRequest({
                      url: `/file_storage/download?asset_type_id=${getters.ACTIVE_MAIN_ASSET.id}&files=${payload}`,
                      method: 'GET',
                      headers: {
                          "Cache-Control": "no-cache",
                          "accept": "application/json",
                          "Authorization": `Bearer ${getters.ACCESS_TOKEN}`,
                      },
                    }, true);
            return response;
        } catch (err) {
            console.error(`${err.name}: ${err.message}`);
            return err.response;
        }
    },
    DOWNLOAD_FILES: async ({ getters }) => {
        const linkFiles = getters.DATA_PRECOLLECT.reduce((link, element) => link + `&files=${element.id}`, '');
        if(!linkFiles) return;
        try {
            const response = await apiRequest({
                      url: `/file_storage/download?asset_type_id=${getters.ACTIVE_MAIN_ASSET.id}${linkFiles}`,
                      method: 'GET',
                      responseType: 'blob',
                      headers: {
                          "Cache-Control": "no-cache",
                          "Authorization": `Bearer ${getters.ACCESS_TOKEN}`,
                      },
                    }, true);
            return response;
        } catch (err) {
            console.error(`${err.name}: ${err.message}`);
            return err.response;
        }
    },
    CANCEL_DOWNLOAD_FILES: ({ getters }) => {
      if (getters.CANCEL_TOKEN_SOURCE) {
        getters.CANCEL_TOKEN_SOURCE.cancel('Download canceled by user');
      }
    },
};

export default {
  state,
  getters,
  mutations,
  actions,
}
