import axios from 'axios';
import { getAllUrl } from '@/services/config/env.js';
import router from '@/router/index.js';
import constants from '@/constants/constants';
import { jwtDecode } from 'jwt-decode';

axios.defaults.baseURL = getAllUrl();

const state = {
    accessToken: '',
    dataAuth: {},
    queryPath: {},
};

const getters = {
  DATA_AUTH: state => {
    return state.dataAuth;
  },
  ACCESS_TOKEN: state => {
    return state.accessToken;
  },
  QUERY_PATH: state => {
    return state.queryPath;
  },
};

const mutations = {
  SET_DATA_AUTH: (state, payload) => {
    state.dataAuth = payload;
  },
  SET_ACCESS_TOKEN: (state, payload) => {
    state.accessToken = payload;
  },
  SET_QUERY_PATH: (state, payload) => {
    state.queryPath = payload;
  },
  RESET_DATA_TOKEN: (state, payload) => {
    localStorage.removeItem(constants.LOCAL_STORAGE_NAME_USER);
    state.accessToken = '';
    state.dataAuth = {};
  },
  REMOVE_QUERY_PATH: (state, payload) => {
    state.queryPath = {};
  },
};

const actions = {
  AUTHORIZATION_USER: async ({commit, dispatch}, payload) => {
    const username = payload.username;
    const password = encodeURIComponent(payload.password);
    
    // console.log(payload)
    try {
      const response = await axios.post(`/auth/login`,
        `username=${username}&password=${password}`,
      {
        headers: {
          "Cache-Control": "no-cache",
          "accept": "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      const res = response.data;

      commit('SET_DATA_AUTH', res);
      commit('SET_ACCESS_TOKEN', res.access_token);
      localStorage.setItem(constants.LOCAL_STORAGE_NAME_USER, JSON.stringify(res.refresh_token));


      const decodedToken = await jwtDecode(res.refresh_token);
      dispatch('GET_DATA_USER', encodeURIComponent(decodedToken.sub));

      return response;
    } catch (err) {
      console.error(`${err.name}: ${err.message}`);
      return err.response;
    }
  },
  REGISTRATION_USER: async (context, payload) => {
    try {
      const response = await axios.post(`/auth/register`,
        payload,
      {
        headers: {
          "Cache-Control": "no-cache",
          "accept": "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })

      return response;
    } catch (err) {
      console.error(`${err.name}: ${err.message}`);
      return err.response;
    }
  },
  REFRESH_ACCESS_TOKEN: async ({getters, dispatch, commit}, payload) => {
    console.log('REFRESH TOKEN')
    try {
      const response = await axios.get(`/auth/refresh`,
      {
        headers: {
          "Cache-Control": "no-cache",
          "accept": "application/json",
          "refresh-token": `${payload}`,
        },
      })
      const res = response.data;
      commit('SET_DATA_AUTH', res);
      commit('SET_ACCESS_TOKEN', res.access_token);

      if(!getters.DATA_USER?.id) {
        const decodedToken = await jwtDecode(res.refresh_token);
        dispatch('GET_DATA_USER', encodeURIComponent(decodedToken.sub));
      }

      localStorage.setItem(constants.LOCAL_STORAGE_NAME_USER, JSON.stringify(res.refresh_token));

      return response.status;
    } catch (error) {
      if(error.response.status === constants.STATUS_CODE.UNAUTHORIZED) {
        localStorage.removeItem(constants.LOCAL_STORAGE_NAME_USER);
        dispatch('RESET_ALL_DATA', '');
        router.push(constants.PATH_NAME.AUTHORIZATION);
      }
      console.error(`${error.name}: ${error.message}`);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
}
