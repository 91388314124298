<template>
    <button class="general_add_button" @click="clickFunction" :disabled="disableState">
        <img src="@/images/icons/add.svg" alt="">
    </button>
</template>
<script>

export default {
    name: 'GeneralAddButton',
    props: {
        clickFunction: {
            type: Function,
            required: true,
        },
        disableState: {
            type: Boolean,
            required: false,
        },
    },
}
</script>
<style>
.general_add_button {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background-color: #FFCC29;
    cursor: pointer;
    img {
        width:  16px;
        height: 16px;
    }
    &:hover {
        background-color: #E6B825;
    }
    &:disabled {
        background-color: #EBEFF5;
        border: 1px solid #EBEFF5;
        cursor: default;
        img {
            filter: brightness(0) saturate(100%) invert(52%) sepia(1%) saturate(861%) hue-rotate(350deg) brightness(86%) contrast(82%);
        }
    }
}
</style>