<template>
    <div class="collect_image_item" @click="selectedElement" >
        <img class="collect_image_view" :src="image.image_preview_path" alt="" v-if="!notFileImage" :onError="imageLoadError">
        <img class="not_file_preview" src="@/images/sprites/svg/not_file.svg" v-else>
        <div class="image_component_btn-remove" @click="removeElement" >
            <img src="@/images/icons/delete.svg" alt="">
        </div>
    </div>
</template>

<script>
export default {
    name: "CollectImageComponent",
    data () {
        return {
            notFileImage: false,
        }
    },
    methods: {
        removeElement() {
            this.$store.commit('REMOVE_ELEMENT_PRECOLLECT', this.index);
        },
        selectedElement() {
            this.$store.commit('SET_ASIDE_IMAGE_DATA', this.image);
        },
        imageLoadError() {
            this.notFileImage = true;
        }
    },
    props: {
        image: {
            type: Object,
            requred: true,
        },
        index: {
            type: Number,
            requred: true,
        }
    }
}
</script>

<style scoped lang="scss">
    .collect_image_item {
        position: relative;
        width: 76px;
        height: 76px;
        padding: 5px;
        background-color: rgba(0, 0, 0, 0.05);
        border-radius: 5px;
        cursor: pointer;
        &:hover::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
            border-radius: 5px;
            background-color: rgba(0, 0, 0, 0.25);
        }
        .collect_image_view {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
        .image_component_btn-remove {
            visibility: hidden;
            position: absolute;
            top: 5px;
            right: 5px;
            z-index: 2;
            width: 16px;
            height: 16px;
            border-radius: 10px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-content: center;
            align-items: center;
            img {
                display: block;
                width: 70%;
                height: 70%;
            }
            &:hover {
                img {
                    filter: brightness(0) saturate(100%) invert(84%) sepia(62%) saturate(1565%) hue-rotate(331deg) brightness(101%) contrast(104%);
                }
            }
        }
        &:hover .image_component_btn-remove {
            visibility: visible;
        }
    }
</style>