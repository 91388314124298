<template>
    <ProviderFilterComponent 
        :filterType="filterType"
        :isFilterTypeOpen="isFilterTypeOpen"
        :updateParametersURL="updateParametersURL"
        v-if="filterType.provider" />
    <GameFilterComponent
        :filterType="filterType"
        :isFilterTypeOpen="isFilterTypeOpen"
        :updateParametersURL="updateParametersURL"
        v-else-if="filterType.game_name" />
    <TagsComponent
        :filterType="filterType"
        :isFilterTypeOpen="isFilterTypeOpen"
        :updateParametersURL="updateParametersURL"
        v-else-if="filterType.isTag" />
    <CommonTypesComponent 
        :filterValue="filterType" 
        :isFilterTypeOpen="isFilterTypeOpen"
        :updateParametersURL="updateParametersURL"
        v-else-if="!filterType.children_filter" />
    <StageFilterComponent
        v-for="filterTypeElements in filterType.children_filter"
        :filterStageType="filterTypeElements"
        :updateParametersURL="updateParametersURL"
        :isFilterTypeOpen="isFilterTypeOpen" v-else />
</template>

<script>
import ProviderFilterComponent from './ProviderFilter/ProviderFilterComponent/ProviderFilterComponent.vue';
import GameFilterComponent from './GameFilter/GameFilterComponent/GameFilterComponent.vue';
import CommonTypesComponent from './CommonFilter/CommonTypesComponent/CommonTypesComponent.vue';
import StageFilterComponent from './CommonFilter/StageFilterComponent/StageFilterComponent.vue';
import TagsComponent from './TagsComponent/TagsComponent.vue';
import updateCurrentQuery from '@/utils/updateCurrentQuery/updateCurrentQuery';

export default {
  name: 'CommonFilterElementsComponent',
  data() {
    return {
    }
  },
  components: {
    ProviderFilterComponent,
    GameFilterComponent,
    TagsComponent,
    CommonTypesComponent,
    StageFilterComponent,
  },
  props: {
    filterType: {
        type: Object,
        required: true,
    },
    isFilterTypeOpen: {
        type: Boolean,
        required: true,
    },
  },
  methods: {
    updateParametersURL(element, type, append, childType = undefined) {
        const currentQuery = { ...this.$route.query };
        let activeGames = null;
        if(childType) {
          activeGames = this.$store.getters.ACTIVE_GAMES;
        }

        this.$router.push({query: updateCurrentQuery(currentQuery, element, type, append, childType, activeGames)});
    },
  },
};
</script>
