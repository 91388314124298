<template>
    <div class="button_get_all" @click="getAllFunction">
        <div class="button_get_all_title">All {{ nameGetElements.toLowerCase() }}</div>
        <div class="button_get_all_icon">
            <img class="button_get_all_arrow" src="@/images/sprites/svg/arrow-down.svg"/>
        </div>
    </div>
</template>
<script>

export default {
  name: 'GetAllButtonComponent',
  data() {
    return {}
  },
  props: {
    getAllFunction: {
        type: Function,
        required: true,
    },
    nameGetElements: {
        type: String,
        required: true,
    },
  },
};
</script>
<style scoped lang="scss">
  .button_get_all {
    position: relative;
    display: flex;
    align-content: center;
    justify-content: space-between;
    border-radius: 5px;
    font-weight: 600;
    border: 1px solid rgba(117, 117, 117, 0.5);
    padding: 5px 10px;
    margin-right: 10px;
    cursor: pointer;
    &:hover {
      border: 1px solid rgba(117, 117, 117);
    }
    .button_get_all_icon {
      display: flex;
      justify-content: center;
      align-content: center;
      .button_get_all_arrow {
        transform: rotate(270deg);
      }
    }
  }
</style>